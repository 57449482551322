import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/session09/bitter-reach-page-banner-session-09.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";

import cover from "../../../../src/assets/forbidden-lands/session09/fbl-session-09-cover.webp";
import serpents from "../../../../src/assets/forbidden-lands/session09/aether-serpent.webp";
import skeletons from "../../../../src/assets/forbidden-lands/session09/skeleton-room.webp";
import blissxir from "../../../../src/assets/forbidden-lands/session09/blissxir.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLSession09 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Æther Serpents",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={cover}
            caption="Æther Serpents"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="In a lava-filled temple room, aether serpents flicker out to ambush from the dancing shadows on the chamber walls."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The party is down to Ibax, Lobo, Stilak, and Rog-Golar this session.
          But, they are still travelling with Company Zed (Aileen, Neha, Shima,
          and Tiemiesz) so the party is strong. They{" "}
          <span style={{ color: "burlywood" }}>
            open the dragon door and head north into the ruins of Ruenlocke
          </span>
          . They find:
          <br />
          <br />
          <h2>The Temple Room</h2>
          <i>
            This chamber has six pews facing an altar on a ledge over a deep pit
            fulled with lava. The bloody glow of the hot magma casts strange
            shadows throughout the room. The shadows on the walls seem to have a
            life of their own. Meanwhile, is that ancient chanting you hear from
            near the altar?
          </i>
          <br />
          <br />
          It is in fact chanting they hear. Long ago, forgotten,{" "}
          <span style={{ color: "burlywood" }}>
            chanting moans . . . perhaps some dirge of the forgethralls of
            yesteryear
          </span>
          ? The dirge does nothing to lessen the sense that the room is alive,
          the lava glow seeming to cast dancing shadows on the chamber walls.
          Suddenly, a mass of serpents appears. The serpents are red and/or
          partially translucent. The mass of 2-6 serpents seems to flicker in
          and out of solidity as small racing white glowing lights run along the
          length of their massed, writing bodies.{" "}
          <span style={{ color: "burlywood" }}>
            It would seem these creatures are aether serpents. All anyone knows
            is that the aether, or astral, realm is considered to be a place
            where the dreams and fears of the ages accumulate
          </span>
          . Besides the lore, the serpents attack as a mass with hallucinagenic
          poison and ambush the party as they materialize from the shadow
          lights.
          <br />
          <br />
          <ImageComponent
            image={serpents}
            caption="Aether Serpent"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="When the astral wind blows strangely, the sands of that realm form into writhing serpents which grind along the dunes in pursuit of material travelers. The sands of the astral realm are soaked in the dreams and fears of the ages, so the bite of an Aether Serpent inflicts potent, incapacitating visions of lives long past. Do not tarry in the astral realm!"
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The fight goes well, and even the surprise arrival of a second mass of
          aether serpents does no daunt the party's momentum. We see that Lobo's
          severed leg slows his ability to maneuver on the battlefield, but if
          close enough he can still melee charge and is dangerous. Ibax uses his
          sling and forms the archery phalanx with Neha and Stilak as Rog-Golar,
          Lobo, and the rest of Company Zed stick to arms length melee. It's a
          matter of time before the serpents are wiped out.
          <br />
          <br />
          The party risks random encounters to{" "}
          <span style={{ color: "burlywood" }}>
            rest in the Lava Room for a QUARTER DAY
          </span>{" "}
          since it is warm and dry. Everyone heals and{" "}
          <span style={{ color: "burlywood" }}>
            Lobo and Stilak both work to train and earn the talent PACK RAT
          </span>
          . Lobo fails, perhaps due to adjustment to missing leg, but he
          activates his PRIDE "won't show weakness in front of the party" and
          with the pride dice roll succeeds. Stilak also fails rool then
          activates his PRIDE as well which is "I will not abandon my friends"
          which means he hangs in there helping Lobo. And as Lobo did succeed,
          Stilak fails the pride roll. He will have no pride dice next session.
          <br />
          <br />
          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
    {
      title: "The War Room",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={skeletons}
            caption="Mysterious Pyramid"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="This room has half a dozen skeletons scattered across the floor. Their ancient gear is strewn about as well. There are old hack marks in the pillars holding up the roof and rubble throughout the room making for rough ground. There is a dull purple glow from behind a jagged hole in closed, but partially shattered door to the north. You see a crate just out of reach inside the collapsed room behind the door and you hear the rattle of bones as skeletons rise from the ground for battle!"
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The next room is the War Room. The party finds:
          <br />
          <br />
          <i>
            This room has half a dozen skeletons scattered across the floor.
            Their ancient gear is strewn about as well. There are old hack marks
            in the pillars holding up the roof and rubble throughout the room
            making for rough ground. There is a dull purple glow from behind a
            jagged hole in closed, but partially shattered door to the north.
            You see a crate just out of reach inside the collapsed room behind
            the door and you hear the rattle of bones as skeletons rise from the
            ground for battle!
          </i>
          <br />
          <br />
          <span style={{ color: "burlywood" }}>
            The party stands it's ground in the corridor to avoid battling on
            ROUGH terrain with the skeletons and ghouls.
          </span>{" "}
          The battle gets crowded as the undead simply march forward like
          deathly automatons. Tiemiesz hangs back due to a SKEWERED FOOT injury,
          but the rest of the party methodically and effectively chops the
          restless dead down like stubborn trees. We see Rog-Golar's battleaxe
          is deadly, that Lobo can still take out two skeletons in one round
          with MELEE CHARGE and SWORDSMANSHIP talents. Stilak breaks his bow and
          uses a Winter Elf shortsword on loan to whet his melee combat skills.
          <br />
          <br />
          <ImageComponent
            image={blissxir}
            caption="Blissxir"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Blissxir is a magical potion from the age of the Winter Elves. The effect is completely unknown at the time they are found."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Once the skeletons are defeated, the party finds the source of the
          purple glow: a crate with{" "}
          <span style={{ color: "burlywood" }}>
            five delicate, beautiful glass bottles from the age of the winter
            elves
          </span>{" "}
          filled with bright glowing purple liquid with red globs that slowly
          rise to the top of the bottle, drift back down, then rise up again.
          The "potion" is called Blissxir but the effect is a complete mystery.
          <br />
          <br />
          So much treasure is found so far in Ruenlocke that the PACK RAT talent
          is becoming attractive. Some items are left in the LAVA room and
          inventories have to be shifted around.
          <br />
          <br />
          Some of the treasure found in Lava Room and War Room are:
          <ul>
            <li>Rare Book (5d6 silver), light</li>
            <li>Tiny Gold Amulet (23 silver), tiny.</li>
            <li>Five bottles of Blissxir.</li>
            <li>Copper Plate (3d6 copper)), normal</li>
            <li>Gold Bracelet (21 silver), tiny</li>
            <li>Large Rare Gemstone (2d6 gold), light</li>
            <li>Bronze Earring 3 (4d6 copper), tiny</li>
            <li>Pearl (2d6 silver), tiny</li>
            <li>Elegant Helmet 3, a fancy spanghelm (8 + 1d6 silver), light</li>
          </ul>
          <a href="#pageTop">Back to Top</a>
          <br />
          <br />
          <small>Attendees: Ibax, Lobo, Rog-Golar, Stillak.</small>
          <br />
          <small>Ending Datetime: 3am, Springrise 01, 1165</small>
          <h5>XP</h5>
          <ul>
            <li>Session 1.0</li>
            <li>Legend: 0.0</li>
            <li>Lore: 0.0</li>
            <li>Hex: 0.0</li>
            <li>Pride: 1.0 (Ibax, Stilak: failed, no pride next sesison)</li>
            <li>Treasure: 1.0</li>
            <li>Monster: 1.0</li>
            <li>Dark Secret: 0.0</li>
          </ul>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title
                id="cardTitle-forbidden-lands"
                className="cardTitle-forbidden-lands  "
              >
                Party battles Æther serpents, more restless dead, and find
                treasure including a mysterious potion named Blissxir.
                <br />
                <br />
                <small>
                  ⇐ Session 08:{" "}
                  <a href="./forbidden-lands-session-08">The Crimson Raven</a> ☠{" "}
                  <a href="./forbidden-lands-session-10">
                    Session 10: The Sting
                  </a>{" "}
                  ⇒{" "}
                </small>
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLSession09;
