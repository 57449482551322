import React from "react";
import "./image.styles.scss";

const ImageComponent = ({
  image,
  cssClass,
  caption,
  handleClick,
  altText,
  modalBody,
}) => {
  if (!cssClass) {
    cssClass = "imageLeft";
  }
  if (!altText) {
    altText = caption;
  }
  const classNames = `hover01 ${cssClass}`;
  return (
    <div onClick={() => handleClick(image, modalBody)} className={classNames}>
      <figure>
        <img alt="altText" src={image}></img>
        <div className="imageCaption">{caption}</div>
      </figure>
    </div>
  );
};
export default ImageComponent;
