import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/session05/bitter-reach-page-banner-session-05.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";

import tulok from "../../../../src/assets/forbidden-lands/session05/tulok-fook.webp";
import ibak from "../../../../src/assets/forbidden-lands/session05/ibak-token.webp";
import stars from "../../../../src/assets/forbidden-lands/session05/when-stars-fall.gif";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLSession05 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "The Game is Afoot",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={tulok}
            caption="Tulok Fook, Fighter"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Tulok is a male ailander fighter who wandered in his childhood. He knows Ibak and Stilak; they came from the same small village."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
       <ImageComponent
            image={ibak}
            caption="Ibak the Druid"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Ibax is a male ailander druid. He wandered in his childhood. He knows Tulok and Stillak; they came from the same small village."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <span style={{ color: "burlywood" }}>Tulok and Ibak join the hunt for Turold Blood-eye.</span> They know each other and may be from the same lost village; details of their backstory to be 
          fleshed out as needed (click pictures to get details in popup on these new party members).
          <br/><br/>
          As the party leaves Northfall, it falls to Tulok to KEEP WATCH and IBAK to be the pathfinder. Lobo keeps the party on the trail of Turold thanks to his HUNTING INSTINCT. They travel
          several days and so far the trail follows the curves and bends of the river Keld heading west toward the meeting of <span style={{ color: "orange" }}>Garme's Edge</span> and <span style={{ color: "orange" }}>Kastor's Edge</span> where 
          the <span style={{ color: "orange" }}>Morma Glacier</span> towers above.
         <br/><br/>
          Along the way are some of the typical difficulties of travel in the Bitter Reach, including:
          
          <ul>
            <li>Tulok cracks his head while keeping watch as they hike through the darkness of Winterwane (a TRUE WINTER month with only one quarter of light for an entire day).</li>
            <li>Lobo sees an apparition and breaks down crying from broken WITS.</li>
            <li>Ibax fails to heal Lobo from WITS damage mentioned above by doing a dance with his druid staff; the athletic dance causes Ibax to fall and take two AGILIY damage.</li>
            <li>Tulok then heals Lobo by saying <span style={{ color: "lightgreen" }}>"brother, I've been there"</span> and showing that a fellow fighter does not judge a moment of doubt and tears as weakness; it's part of the job.</li>
            <li>Ibax is finding the path and falls into a crevasse. He uses his PRIDE but still suffers a critical injury: KNOCKED OUT TEETH.</li>
          </ul>
          That evening the players make camp and witness an astonishing celestial event:
          <br/><br/>
          
          <ImageComponent
            image={stars}
            caption="When Stars Fall"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody=""
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>          
          
          <i>This night when you make camp you see shimmering <b>aurora borealis</b> in the sky. These are not the first northern lights you've seen, but they are the most colorful. They shimmer in red, green and blue, then turn into a golden glow shining over the ice.
<br/><br/>
It feels as if <b>the whole world holds its breath for an infinite instant</b>. Then a star falls, then another one, and yet another, before a veritable shower of stars falls 
across the icy vista. Finally, a <b>star shoots upward</b> from a location in the Bitter Reach, almost as if the star is escaping the prison of this world. Booming thunder rolls over you before all goes quiet.</i>
          
          <br/><a href="#pageTop">Back to Top</a>
          <br/><br/>
          <small>Attendees: Blisster, Tulok, Ibax, Lobo, (Rog-Golar, not present but received XP due to GM mischedule).</small><br/>
          <small>Ending Datetime: 6am, Winterwane 41, 1165</small>
          <h5>XP</h5>
          <ul>
            <li>Session 1.0 (Blisster, Tulok, Ibak, Lobo, Rog-Golar)</li>
            <li>Legend: 0.0</li>
            <li>Lore: 0.0 </li>
            <li>Hex: 1.0</li>
            <li>Pride: 1.0 (Ibax on Lead the Way roll)</li>
            <li>Treasure: 0.0</li>
            <li>Monster: 0.0</li>
            <li>Dark Secret: 0.0</li>
          </ul>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title id="cardTitle-forbidden-lands" className="cardTitle-forbidden-lands  ">
              Our explorers fight snow storms and witness a celestial miracle: a shining star that blasts into the sky as if escaping the world.
                <br /><br/>
                <small>
                  ⇐ Session 04:{" "}
                  <a href="./forbidden-lands-session-04">
                    Murder In Northfall
                  </a>{" "}
                  ☠{" "}
                  <a href="./forbidden-lands-session-06">
                    Session 06: Ruins of Ruenlocke
                  </a>{" "}
                  ⇒{" "}
                </small>
                
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLSession05;
