import React from "react";
import "./modal.styles.scss";
import Modal from "react-bootstrap/Modal";
import { Interweave } from "interweave"; // allows html string output without setting dangerous helpers
import { useEffect, useState } from "react";

const ModalComponent = ({
  show,
  onHide,
  image,
  cssClass,
  modalBody,
  modalData,
}) => {
  console.log(`IMAGE: ${image}`);
  console.log(`MODAL BODY: ${modalBody}`);
  console.log(`MODAL DATA: ${JSON.stringify(modalData)}`);
  // const [imageRef, setImageRef] = useState("");
  // const [modalBodyRef, setModalBodyRef] = useState("");
  const [modalDataRef, setModalData] = useState("");
  // useEffect(() => {
  //   console.log("modal image effect");
  //   console.log(image);
  //   setImageRef(image);
  // }, [image]);
  // useEffect(() => {
  //   console.log("modal body effect");
  //   console.log(modalBody);
  //   setModalBodyRef(modalBody);
  // }, [modalBody]);
  useEffect(() => {
    console.log("modal data effect");
    console.log(modalData);
    setModalData(modalData);
  }, [modalData]);

  if (!cssClass) {
    cssClass = "imageLeft";
  }
  if (show) {
    console.log("showModal is true");

    return (
      <Modal
        key={image}
        scrollable
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="false"
        onHide={onHide}
        onClick={onHide}
      >
        <Modal.Body>
          <div className="modal-title">
            <Interweave content={modalDataRef.body}></Interweave>
          </div>
          <br />
          <img
            className="img-fluid"
            alt={modalDataRef.image}
            src={modalDataRef.image}
          ></img>
        </Modal.Body>
      </Modal>
    );
  } else {
    return "";
  }
};

export default ModalComponent;
