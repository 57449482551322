import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/session10/bitter-reach-page-banner-session-10.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";

import cover from "../../../../src/assets/forbidden-lands/session10/fbl-session-10-cover.webp";
import awakening from "../../../../src/assets/forbidden-lands/session10/awakening-day.webp";
import locket from "../../../../src/assets/forbidden-lands/session10/mysterious-locket.webp";
import blissxir from "../../../../src/assets/forbidden-lands/session09/blissxir.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLSession10 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "The Sting",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={cover}
            caption="The Sting"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="In a pool-filled room with of corridors, bandit wasps attempt to eat the party."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The party this session is the full roster: Ibax, Lobo, Stilak,
          Rog-Golar, Tulok Fook, and Blisster. Continuing ever deeper into the
          ancient dwarf stronghold of Ruenlockehey, they find:
          <br />
          <br />
          <h2>The Pool Room</h2>
          <i>
            This room splits into <strong>three parallel corridors</strong>. The
            one to the west is a <strong>dead end with a pool of water</strong>{" "}
            at the end. The middle corridor has{" "}
            <strong>closed double-doors</strong>, and the eastern corridor is
            <strong>filled with water</strong>. At the bottom of the water you
            see just the{" "}
            <strong>torso of a corpse in rusted plate armor</strong>. There is a
            <strong>small ledge above the pool</strong> with a{" "}
            <strong>small dark cave</strong>.
          </i>
          <br />
          <br />
          Blisster examines the small, less epic doors that appear to be cousins
          to the ornate dragon doors that lead into the heart of Ruenlocke. The
          silver dragon key does not work on these doors and lockpicking does
          not work.{" "}
          <span style={{ color: "burlywood" }}>
            Lobo enlists Rog-golar and Aileen to FORCE the doors open
          </span>
          . The doors resist then give way with an enormous crash. Lobo narrowly
          avoids tipping into the watery corridor beyond the doors. A loud
          buzzing sound fills the corridor and three monstrous bandit wasps
          attack from the dark crevices of the walls and ceiling of the
          water-filled corridor.
          <br />
          <br />
          The wasps have poison and very tough chitin analogous to chainmail in
          toughness. The party is bereft of ranged attacks since Stilak's bow is
          broken and Neha is out of ammo. And so, everyone in the party stands
          their ground waiting for the wasps to come to them. As the battle
          progresses, Aileen succeeds with a LORE role and recalls that he heard
          from a drunken treasure hunter that these wasps are rare, found only
          near volcanic heat and that their eyes are a weak point.
          <br />
          <br />
          <span style={{ color: "lightgreen" }}>
            "Go for the eyes! Harder to hit lads, but no armor and it hurts like
            hell. The eyes!
          </span>
          <br />
          <br />
          Rog-golar shouts out a plan as the party struggles with the wasps and
          more emerge from the dark cave in the eastern corridor.
          <span style={{ color: "lightgreen" }}>
            "We can fall back and use the doors to setup a choke point!"
          </span>
          Ibak responds by turning to the middle corrider and leaping into the
          water. The ways of a shaman are inscrutible to Rog-golar who stands
          his ground whilst shaking his head.
          <br />
          <br />
          <ImageComponent
            image={locket}
            caption="Mysterious Locket"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="A mysterious locket with an engraving and filled with pink powder is found in lockbox built into the plate mail of corpse torso."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The gang takes out the bandit wasps with some highlight:
          <ul>
            <li>Rog-golar blasting wasps with his battle-axe.</li>
            <li>
              Stilak proving no slouch as a melee fighter as he uses the Winter
              Elf shortsword.
            </li>
            <li>
              The fight includes liberal use of the SWING WEAPON fast action
              which allows a weapon with HEAVY feature to do +1 damage.
            </li>
            <li>
              Blisster kills a wasp by fighting poison with poison as he preps
              his Winter Elf Dagger with LETHAL POISON and showing off his Path
              of the Poisoner talents.
            </li>
            <li>
              Neha is paralyzed and spends the combat encounter on the ground
              unable to move.
            </li>
            <li>
              Lobo does a one-legged MELEE CHARGE into the air over the flooded
              corrider and uses his talents to get two attacks mid-air to
              destroy the wasp before slashing into the water.
            </li>
            <li>
              WITS attack by wasps breaks Aileen, Neha, and Tiemiesz who suffer
              horror criticals of ANXIOUS, AMNESIA, and TREMBLING respectively.
            </li>
          </ul>
          <br />
          <br />
          After the battle, Stilak has to contend with Neha. Stilak heals her
          broken wits by performing acrobatics twirling an arrow between his
          fingers dextrously. As Neha's wits are restored and the paralysis
          wears off, we realize she has suffered a horror critical injury and it
          is AMNESIA. Given her hatred of non-human kin, she is shocked and
          ready to fight as the paralysis wears off. Stilak knows the elf better
          than most and approaches cautiously as she crab-walks backwards
          scrambling to her feet and fumbling for her apache knife.
          <span style={{ color: "lightgreen" }}>
            "We are all your friends"
          </span>{" "}
          Stilak says as he shows her his broken bow. He then points to her
          quiver so she realizes she out of ammuniation.
          <span style={{ color: "lightgreen" }}>"We won't hurt you."</span> he
          finishes as her gives her his last arrows. Neha realizes she may be
          among friends. As she is outnumbered in any case, her natural distrust
          of other kin gives way a wary sense of fleeting trust for the time
          being.
          <br />
          <br />
          The party finds treasure in the flooding pit trap, on the body of the
          torso in rusted plate armor, in the wasp cave, and in the pool of
          water in the western corridor. As the party gathers their loot, they
          decide to risk random encounters and they rest for a quarter day.
          Then, they spend another quarter day having Ibax train Rog-golar,
          Stilak, Tulok Fook, and Blisster the PACK RAT talent. Even if they
          find Mouse the Moose and the dog sled undisturbed, they know they will
          be overburdened with treasure if they are not strategic.
          <br />
          <br />
          Lobo trains to try and reach MELEE CHARGE Level 2. He fails wits roll
          and uses PRIDE. The Pride also fails so Lobo will be without a pride
          die next session.
          <br />
          <br />
          As they rest and train, someone realizes it is now Awakening Day and
          festival of Springrise will be starting up in Northfall. Awakening Day
          is a major holiday that marks the first phase of Spring (or Breakup as
          it is called in the Bitter Reach). The festival celebrates darkness
          giving way to light. The celebrations focus on thanking the gods for
          for surviving winter. The emphasis is on prophecies and telling
          lessons learned from the previous year. The story of Jura the Giantess
          is told in the Bitter Reach. Bad weather is hoped for during the
          celebrations as the bad weather is said to repel Jura so the partiers
          can celebrate in peace. In Ravenland, lambs feature heavily in the
          feasts of Awakening Day. The celebrated skill of this holiday is
          INSIGHT.
          <ImageComponent
            image={awakening}
            caption="Awakening Day"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Awakening Day marks the beginning of Springrise, the most forgiving season of the year in the harsh Bitter Reach."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <br />
          <br />
          The treasure found includes:
          <ul>
            <li>Sigil Stone (D6 silver, no weight)</li>
            <li>Bronze Drinking Horn (4D6 copper, normal weight)</li>
            <li>Golden Embroidery (4 gold, tiny weight)</li>
            <li>Rare gemstone (2d6 gold, tiny weight)</li>
            <li>
              Winter Elf Helmet (value uncertain, light weight when closed
              helmet would usually be normal weight)
            </li>
          </ul>
          Next session will likely be the conclusion of Ruenlocke and the trip
          back to Northfall. The players discuss how Ruenlocke would make a good
          stronghold. A bold plan that could be quite interesting.
          <br />
          <br />
          <small>
            Attendees: Blisster, Ibax, Lobo, Rog-Golar, Stillak, Tulok Fook.
          </small>
          <br />
          <small>Ending Datetime: 6pm, Springrise 01, 1165</small>
          <h5>XP</h5>
          <ul>
            <li>Session 1.0</li>
            <li>Legend: 0.0</li>
            <li>Lore: 0.0</li>
            <li>Hex: 0.0</li>
            <li>Pride: 1.0 (Lobo: failed, no pride next session)</li>
            <li>Treasure: 1.0</li>
            <li>Monster: 1.0</li>
            <li>Dark Secret: 0.0</li>
          </ul>
          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title
                id="cardTitle-forbidden-lands"
                className="cardTitle-forbidden-lands  "
              >
                Party battles gigantic bandit wasps and discovers more treasure
                in the heated pool room of Ruenlocke.
                <br />
                <br />
                <small>
                  ⇐ Session 09:{" "}
                  <a href="./forbidden-lands-session-09">
                    Æther Serpents of Ruenlocke
                  </a>{" "}
                  ☠{" "}
                  <a href="./forbidden-lands-session-11">
                    Session 11: Panoowa, Ghost Boy
                  </a>{" "}
                  ⇒{" "}
                </small>
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLSession10;
